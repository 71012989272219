const modal       = document.getElementById('confirmAgeModal');
const modalSubmit = document.getElementById('confirmAgeSubmit');
const modalClose  = document.getElementById('confirmAgeClose');

const getCookie = (name) => {
	name = name + '=';

	let decodedCookie = decodeURIComponent(document.cookie),
		cookies       = decodedCookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i].trim();

		if (cookie.indexOf(name) === 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}
};

const setCookie = (name, value, days) => {
	let expires = '';

	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = `; expires=${date.toUTCString()}`;
	}
	document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

const showModal = () => {
	modal.style.display = 'block';
};

const hideModal = () => {
	modal.style.display = 'none';
	setCookie('ageVerified', 1, 7);
};

document.addEventListener('DOMContentLoaded', () => {
	if (!getCookie('ageVerified')) {
		showModal();
	}
});

modalSubmit.addEventListener('click', hideModal);
